<script>
	import {onMount} from "svelte";
	var hash = window.location.hash;
	var pathname = window.location.pathname;
	var inInsta = navigator.userAgent.includes("Instagram") ? 1 : 0;
	Object.defineProperty(HTMLMediaElement.prototype, 
	'playing', {
		get: function() {
			return !!(this.currentTime > 0 && !this.paused && !this.ended && this.readyState > 2);
		}
	});

	onMount(() => {
		var videoElement = document.getElementById('background-video');
		if(!videoElement.playing){
			videoElement.play();
		}
		videoElement.addEventListener('suspend', (e) => {
			e.preventDefault();
			videoElement.play();
		});

		document.body.onclick = function () {
			if (!videoElement.playing) {
				videoElement.play();
			} 
		}
	});
	
</script>

<main>
	<video id="background-video" autoplay="autoplay" loop="loop" muted defaultMuted playsinline  oncontextmenu="return false;"  preload="auto" >
		<source src="https://vanceeris.s3.amazonaws.com/vanceeris-bg-compressed.mp4" type="video/mp4">
	</video>
	<div id="links">
		<a id="spotify" target="_" href="https://open.spotify.com/artist/0Xly8hzEEWdZJmmpIVAkv3?si=wikYQhclQ-GzXPKHCKcbRg"><img alt="spotify" width="40px" src="https://img.icons8.com/ios-glyphs/120/000000/spotify.png"/></a>
		<a id="apple" target="_" href="https://music.apple.com/us/artist/vance-eris/1586697212"><img alt="apple music" width="30px" src="https://img.icons8.com/ios-filled/150/000000/apple-music.png"/></a>
		<a id="youtube" target="_" href="https://www.youtube.com/channel/UCIKrt9EUIREs7AhMyox-FJQ"><img alt="youtube" width="48px" src="https://img.icons8.com/material-rounded/192/000000/youtube-play.png"/></a>
		<a id="instagram" target="_" href="https://instagram.com/vanceeris"><img alt="instagram" width="30px" src="https://img.icons8.com/ios-glyphs/120/000000/instagram-new.png"/></a>
	</div>	
	<a on:click="{() => gtag('event', 'contact_click')}" id="contact" href = "mailto: josh@tezanrecords.com">CONTACT</a>
	<a id="tag" >VANCE ERIS &#169; 2022</a>
</main>

<style>
	* {
		user-select: none;
	}
	#contact {
		position: absolute;
		bottom: 2rem;
		margin-top:1rem;
		color: yellow;
		text-decoration: none;
		font-size: 1.2rem;
		text-shadow: 0px 0px 15px rgba(255, 255, 0, 0.829);
	}
	#tag{
		position: absolute;
		bottom: 1rem;
		margin: 0 auto;
		font-size: 7px;
		text-decoration: none;
		color: yellow;
		text-shadow: 0px 0px 5px rgba(255, 255, 0, 0.829);
	}
	main {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin-bottom: 5rem;
	}

	#background-video {
		width: 100vw;
		height: 100vh;
		object-fit: cover;
		position: fixed;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		z-index: -1;
	}
	#links {
		display: flex;
		align-items: center;
		justify-content: space-evenly;
		width: 15rem;
	}

	img {
		filter: invert(94%) sepia(36%) saturate(1443%) hue-rotate(358deg) brightness(104%) contrast(102%) drop-shadow(0px 0px 10px rgb(255, 255, 0));
	}
	#links > a > img {
		color: yellow;
	}
</style>
